






















import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Download extends Vue {
  googlePlay() {
    console.log("Google Play");
  }
  appStore() {
    console.log("App Store");
  }
}
