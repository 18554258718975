

















































import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class App extends Vue {
  private isBaseUrl = true;
  private open = false;

  mounted() {
    if (window.location.pathname != "/") {
      this.isBaseUrl = false;
    } else {
      this.isBaseUrl = true;
    }
  }

  openNav() {
    if (this.open) {
      this.open = false;
    } else {
      this.open = true;
    }
  }
}
