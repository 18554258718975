















































import { Component, Vue } from "vue-property-decorator";
import Download from "./Download.vue";

@Component({
  components: {
    Download,
  },
})
export default class ContactUs extends Vue {}
