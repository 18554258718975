










import { Component, Vue } from "vue-property-decorator";
import FAQ from "../components/FAQ.vue";
import ContactUs from "../components/ContactUs.vue";
import Landing from "../components/Landing.vue";
import Information from "../components/Information.vue";
import HowItWorks from "../components/HowItWorks.vue";

@Component({
  components: {
    FAQ,
    ContactUs,
    Landing,
    Information,
    HowItWorks,
  },
})
export default class Home extends Vue {}
