





























































import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class HowItWorks extends Vue {
  sellerIndex = -1;
  buyerIndex = -1;

  private seller = [
    "Launch the Tapaolawei App",
    'Select "Make a Tapao Offer"',
    "Create Tapao Offer",
    "New Tapao Offer",
  ];

  private buyer = [
    "Launch the Tapaolawei App",
    'Select "Make a Tapao Request"',
    "Request and Submit",
    "Request Posted on Feed",
  ];

  updateSellerIndex(index: number) {
    this.sellerIndex = index;
  }

  updateBuyerIndex(index: number) {
    this.buyerIndex = index;
  }
}
