




























import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class FAQ extends Vue {
  private isOpen = -1;
  private content = [
    {
      title: "What is Tapaolawei?",
      text: "The name speaks for itself. There is only Tapaolawei. Simple, tracked DIY food orders. You hangry? We gotchu. We are literally everywhere, soon.",
    },
    {
      title: "Why Tapaolawei la?",
      text: "KITA JAGA KITA. Nuff said. We encourage and support lokal merchants.",
    },
    {
      title: "Is Tapaolawei FREE ka?",
      text: "In life, nothing is FREE. In Tapaolawei, the app is free. The food you pay lah.",
    },
    {
      title: "Is Tapaolawei FREE for Merchants ka?",
      text: "We can always slow talk, don’t worry.",
    },
    {
      title: "How do you Tapaolawei?",
      text: `There are only 3 wei<br><br>
              <b>1) Make a Tapao Offer – Seller wanna make money sell food </b><br>
              Offer your Tapao Services to other people (i.e. sell your yummy homemade food)<br><br>
              <b>2) Make a Tapao Request – Hangry people craving for food </b><br>
              Buyer buy food for Tapao Requester (i.e. request for someone to be a “personal shopper” and buy food for you)<br><br>
              <b>3) Hangry people browse listing and place order</b>
            `,
    },
    {
      title: "How to sign up?",
      text: "With your email and password. SETTLE.",
    },
  ];
}
