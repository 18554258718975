

































import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ContactUs extends Vue {
  private firstName = "";
  private lastName = "";
  private email = "";
  private message = "";

  submitForm() {
    console.log({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      message: this.message,
    });
  }
}
